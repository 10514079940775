<template>
  <Popup
    size="lg"
    modal-id="bundle-module-remark-single"
    custom-class="gray-header"
    :on-close="close"
    :scrollable="true"
    :toggle-status="showPopup"
  >
    <template v-slot:header>
      <div class="bundle-module-remark__header">
        <span>{{ labels.edit_note_for }}</span> :
        <span>{{ remark.title }}</span>
      </div>
    </template>

    <template v-slot:body>
      <FormulateForm
        class="bundle-module-remark__form"
        id="bundle-module-remark-form"
      >
        <FormulateInput
          v-model="values.title"
          type="text"
          name="title"
          :label="labels.title"
          error-behavior="blur"
        />
        <FormulateInput
          v-model="values.text"
          type="textarea"
          id="formulate-input-textarea"
          name="text"
          :label="labels.content"
          error-behavior="blur"
        />
      </FormulateForm>
    </template>

    <template v-slot:footer>
      <Button
        :label="labels.delete"
        variant="btn-sienna"
        data-dismiss="modal"
        style="fontweight: 600"
        :action="isDelete"
      />
      <div class="remark-buttons">
        <Button
          :label="labels.close"
          variant="btn-outline-purple"
          data-dismiss="modal"
          style="fontweight: 600"
          :action="close"
        />
        <Button
          :label="labels.update"
          variant="btn-purple"
          data-dismiss="modal"
          style="fontweight: 600"
          :action="isUpdate"
        />
      </div>
    </template>
  </Popup>
</template>

<script>
import Popup from "@/components/Popups/Popup";
import Button from "@/components/Buttons/Button";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "BundleModuleRemark",
  components: {
    Popup,
    Button,
  },
  data() {
    return {
      values: {
        title: "",
        text: "",
      },
      showPopup: true,
    };
  },
  mounted() {
    this.loadRemark();
  },
  computed: {
    ...mapState("account", ["remark"]),
    ...mapGetters("helper", ["labels"]),
  },
  methods: {
    ...mapActions("account", ["updateRemark", "deleteRemark", "getRemark"]),
    ...mapActions("notification", ["addNotification"]),
    loadRemark() {
      this.getRemark(this.$route.params.remark_id)
        .then((res) => {
          if (res.error) {
            this.addNotification({ variant: "danger", msg: res.error });
          }
        })
        .catch((err) => console.log(err));
    },
    isUpdate() {
      // updated remark
      this.updateRemark({
        id: this.remark._id,
        title: this.values.title,
        text: this.values.text
      }).then((res) => {
        if (res.success) this.openParentPage();

        if (res.error) {
          this.addNotification({ variant: "danger", msg: res.error });
        }
      }).catch((err) => console.log(err));
    },
    isDelete() {
      this.deleteRemark({
        id: this.$route.params.remark_id,
      }).then((res) => {
        if (res.success) this.openParentPage();

        if (res.error) {
          this.addNotification({ variant: "danger", msg: res.error });
        }
      }).catch((err) => console.log(err));
    },
    close() {
      //close popup
      this.values.title = "";
      this.values.text = "";
      this.openParentPage();
    },
    openParentPage() {
      this.$router.push(
          {
              name: "bundle-module",
              params: {
                  monetization_bundle_id: this.$route.params.monetization_bundle_id,
                  module_id: this.$route.params.module_id
              }
          }
      );
    }
  },
  watch: {
    remark(newValue) {
      if (!newValue?.title || !newValue?.text) return;

      this.values.title = newValue.title;
      this.values.text = newValue.text;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .modal-footer {
    justify-content: space-between;
    align-items: center;
    .remark-buttons {
      display: flex;
      button {
        margin: 0.25rem;
      }
    }
  }
  .formulate-input {
    .formulate-input-element {
      max-width: 100% !important;
    }
    .formulate-input-label {
      font-weight: 700;
      margin-bottom: 15px;
    }
  }
  #formulate-input-textarea {
    min-height: 100px;
  }
  .dNone {
    display: none !important;
  }
}
.bundle-module-remark__header {
  font-weight: 800;
}
</style>
